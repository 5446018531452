/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "styles/theme";
import { Annotation, Btn, Container, ProductName, Title, Image } from "../styles";
import { gaEvent } from "utils/ga-utils";
import { useState } from "react";
import FreeTrialModal from "uis/free-trial";
import { openContactSalesLink } from "utils/linkHref";

export default function Section1() {
  const [openModal, setOpenModal] = useState<boolean>(false);
  return (
    <Container>
      <ProductName>Mile Analytics</ProductName>
      <Title>
        공간 데이터를
        <br />
        운영/관리의 인사이트로!
      </Title>
      <Annotation>
        Mile Analytics는 회의실 이용 현황 대시보드, 방문자 현황 대시보드 등을 통하여 기업내에 휘발되는
        <br />
        공간 차원의 데이터를 운영/관리의 인사이트로 재탄생시킵니다.
      </Annotation>
      <Btn
        onClick={() => {
          gaEvent("Analytics-첫화면-도입 문의하기");
          openContactSalesLink();
        }}
      >
        도입 문의하기
      </Btn>
      <Image
        src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/analytics/main.png"
        css={css`
          margin-top: 81px;
          width: 1122px;
          height: 683px;
          margin-bottom: 87px;
        `}
      />
      <FreeTrialModal isOpen={openModal} closeModal={() => setOpenModal(false)} />
    </Container>
  );
}
