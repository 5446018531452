export const MeetingData = {
  제품_이름: "Mile Meeting",
  제목: "회의실 부족 문제,\n시스템 하나로 해결!",
  설명: "Mile Meeting은 자동 체크인 체크아웃으로 회의실 노쇼문제를 해결하며\n회의실 가동률을 높입니다. 회의실 예약 현황 조회 및 바로 예약으로 임직원의 회의실 사용 경험을 개선합니다.",

  기능1: {
    제목: "예약 현황 조회",
    설명1: "회의실 내에서 간편하게 예약 현황을 파악하고\n현재 회의실 사용 행태를 확인할 수 있습니다.",
    설명2: "구글 캘린더 등록 회의들도 Mile Meeting과\n연동되어 표시됩니다.",
  },
  기능2: {
    제목: "회의실 예약",
    설명1: "회의가 필요할 때 빈 회의실에서\n바로 예약을 통해 사용할 수 있습니다.",
    설명2: "회의 중에도 다음 회의를 간편하게\n예약할 수 있습니다.",
  },
  기능3: {
    제목: "회의 시간 연장",
    설명1: "회의 시간이 부족하여 시간이 더 필요하신가요?\n태블릿 내에서 간편하게 회의 시간을 연장해보세요.",
    설명2:
      "바로 뒤에 예약된 회의가 있다면,\n다른 회의실 예약 혹은 뒷 타임 담당자에게\n회의 연장 요청을 보낼 수 있습니다.",
  },
  기능4: {
    제목: "회의실 데이터 활용",
    설명1: "회의실 이용 현황 데이터를 분석해 회의실 부족 원인을 파악하고\n이를 바탕으로 회의실 운영 정책을 세워보세요.",
    설명2: "회의와 관련된 인사이트를 시각화해서 제공해드려요.",
  },
  기능_리스트: [
    {
      제목: "노쇼 정책 설정",
      내용: "자동으로 노쇼가 발생한 회의실 예약을 취소하여 유휴 공간을 확보할 수 있습니다.",
      아이콘: "https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/meeting/functions/group_off.png",
      아이콘_높이: 40,
      아이콘_너비: 40,
    },
    {
      제목: "자동 체크인/체크아웃 기능",
      내용: "회의실 내 음성을 감지하여, 자동으로 회의를 시작 및 종료할 수 있습니다.",
      아이콘: "https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/meeting/functions/adaptive_audio_mic.png",
      아이콘_높이: 40,
      아이콘_너비: 40,
    },
    {
      제목: "메신저 알림",
      내용: "회의 시작 전 알림, 연장 알림 등 회의를 진행하는데 필요한 알림 등을 메신저를 통해 받아볼 수 있습니다.",
      아이콘: "https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/meeting/functions/feedback.png",
      아이콘_높이: 40,
      아이콘_너비: 40,
    },
    {
      제목: "타이머 ON/OFF",
      내용: "남은 시간을 확인하시고 싶으신가요? 상황에 따라 유연하게 태블릿 내 타이머를 ON/OFF 할 수 있습니다.",
      아이콘: "https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/meeting/functions/avg_pace.png",
      아이콘_높이: 40,
      아이콘_너비: 40,
    },
    {
      제목: "민원 기능",
      내용: "태블릿으로 간편하게 회의실에서 발생한 민원을 등록하고 처리할 수 있습니다.",
      아이콘:
        "https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/meeting/functions/notification_multiple.png",
      아이콘_높이: 40,
      아이콘_너비: 40,
    },
    {
      제목: `더 많은 기능이\n추가될 예정입니다.`,
      내용: "",
      아이콘: "https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/visitors/functions/more_horiz.png",
      아이콘_높이: 8,
      아이콘_너비: 28,
    },
  ],
  연동_설명: {
    제목: "다양한 툴과의 매끄러운 연동기능",
    내용: "기존에 사용하고 있던 구글 캘린더, Slack 등의 툴과 매끄러운 연동 기능을 제공합니다.",
  },
};
