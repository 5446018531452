export const openContactSalesLink = () => {
  if (window.location.href.includes("meeting"))
    window.open("https://www.mile.im/contact-sales?utm_source=landing&utm_medium=meeting");
  else if (window.location.href.includes("visitors")) {
    window.open("https://www.mile.im/contact-sales?utm_source=landing&utm_medium=meeting");
  } else if (window.location.href.includes("analytics")) {
    window.open("https://www.mile.im/contact-sales?utm_source=landing&utm_medium=analytics");
  } else if (!window.location.href.includes("product")) {
    window.open("https://www.mile.im/contact-sales?utm_source=landing&utm_medium=home");
  } else {
    window.open("https://www.mile.im/contact-sales");
  }
};
