/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { MainData } from "data/main";
import { useState } from "react";
import { theme } from "styles/theme";
import Button from "uis/button";
import ChannelTalkModal from "uis/component/channel-talk-modal";
import FreeTrialModal from "uis/free-trial";
import { gaEvent } from "utils/ga-utils";
import { openContactSalesLink } from "utils/linkHref";

const Title = styled.p`
  color: ${theme.neutral900};
  font-size: 40px;
  font-weight: 800;
  line-height: 140%;

  @media screen and (max-width: 800px) {
    font-size: 24px;
  }
`;
const LogoImage = styled.img`
  width: 200px;

  @media screen and (max-width: 800px) {
    width: 120px;
  }
`;

const TagContainer = styled.div`
  border-radius: 40px;

  background: radial-gradient(107.32% 141.42% at 0% 0%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.2), -5px -5px 250px 0px rgba(255, 255, 255, 0.02) inset;
  backdrop-filter: blur(21px);

  color: white;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%; /* 24px */

  padding: 12px 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`;

const Section6 = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        margin: 0 auto;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 64px;
          padding: 150px 0 200px 0;

          @media screen and (max-width: 800px) {
            padding: 80px 0;
            gap: 40px;
          }
        `}
      >
        <Title>{MainData.투자사_내용}</Title>

        <LogoImage
          onClick={() => window.open("https://www.mashupventures.co/")}
          src={"https://mile-storage.s3.ap-northeast-2.amazonaws.com/mashup-logo.png"}
        />
      </div>
      <div
        css={css`
          width: 1100px;
          height: 400px;
          margin: 0 auto;
          margin-bottom: 200px;
          display: flex;
          flex-direction: column;

          background-image: url("https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/free-trial-banner.png");
          @media screen and (max-width: 800px) {
            width: 100%;
            margin-bottom: 80px;
            background-position: -30px 0;
          }
        `}
      >
        <p
          css={css`
            color: white;
            text-shadow: 0px 4px 80px #000;
            font-size: 32px;
            font-weight: 800;
            line-height: 150%;
            margin: 80px auto 24px auto;

            @media screen and (max-width: 800px) {
              font-size: 24px;
              margin: 50px auto 24px auto;
            }
          `}
        >
          {MainData.하단_배너.제목}
        </p>
        <div
          css={css`
            display: flex;
            gap: 40px;
            margin: 0 219px 0 auto;

            @media screen and (max-width: 800px) {
              font-size: 24px;
              flex-direction: column;
              gap: 12px;
              margin: 0 auto;
            }
          `}
        >
          {MainData.하단_배너.장점_리스트.map((item, index) => (
            <TagContainer key={index}>{item}</TagContainer>
          ))}
        </div>
        <Button
          onClick={() => {
            gaEvent("Main-고민완화-도입 문의하기");
            openContactSalesLink();
          }}
          title="도입 문의하기"
          styles={{
            container: css`
              padding: 16px 20px;
              box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.4);
              border-radius: 8px;
              width: max-content;
              margin: 64px auto 0 auto;

              @media screen and (max-width: 800px) {
                margin: 40px auto 0 auto;
              }
            `,
            text: css`
              font-weight: 700;
              font-size: 16px;
            `,
          }}
        />
      </div>
      <FreeTrialModal isOpen={openModal} closeModal={() => setOpenModal(false)} />
    </div>
  );
};

export default Section6;
