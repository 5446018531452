/** @jsxImportSource @emotion/react */
import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "pages/home";
import Product from "pages/product";
import React, { useEffect, useState } from "react";
import ChannelService from "utils/ChannelService";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga4";
import ContactSales from "pages/contact-sales";
import ProposalSales from "pages/proposal-sales";
import Earlybird from "pages/ealrybird";

function App() {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE);

  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GOOGLE_GTM,
    });

    ChannelService.boot({
      pluginKey: process.env.REACT_APP_CANNEL_PLUGIN_KEY, // fill your plugin key
    });

    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "page-view" });
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="product/visitors" element={<Product page="visitors" />} />
      <Route path="product/meeting" element={<Product page="meeting" />} />
      <Route path="product/analytics" element={<Product page="analytics" />} />
      <Route path="product/tutorial" element={<Product page="tutorial" />} />
      <Route path="contact-sales" element={<ContactSales />} />
      {/* <Route path="proposal-sales" element={<ProposalSales />} /> */}
      <Route path="event/meeting" element={<Earlybird />} />
    </Routes>
  );
}

export default App;
